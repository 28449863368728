<template>
<div>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          color="green"
          :title="machine.name"
          :text="machine.serialNumber"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>
                <v-btn title="Редактировать ячейку" color="primary" small flat icon @click="pickToEditMapping(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn color="error" small flat icon @click="pickToDeleteMapping(item)" >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
              <td>{{ item.name }}</td>
              <td>
                <span v-if="item.type == 0">Кофейная</span>
                <span v-else-if="item.type == 1">Снековая А</span>
                <span v-else>Снековая B</span>
              </td>
              <td>{{ item.capacity }}</td>
              <td>
                <span v-if="item.active">Активная</span>
                <span v-else>Не активная</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      
    </v-layout>
  </v-container>
  <v-container fluid>
      <v-layout row wrap>
          <v-btn title="Добавить новый мэппинг" color="#66bb6a" @click="addMappingDialog = true">Добавить</v-btn>
          <v-btn title="Ячейки" color="primary" :to="{ name: 'Торговый автомат', params: { id: id }}">Назад</v-btn>
      </v-layout>
    </v-container>
  <v-dialog v-model="addMappingDialog" max-width="600">
      
        <v-card>
            <v-toolbar flat color="#66bb6a">
              <v-toolbar-title left color="blue" class="text--white">Новый мэппинг</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="addMappingDialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-card-text>
            <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-form ref="form" v-model="valid">
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Ячейка</v-flex>
                <v-flex xs8>
                      <v-text-field :rules="rules.required"
                              label="Ячейка" 
                              v-model="newMap.name" 
                              maxlength="5"
                              counter
                              ></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Тип</v-flex>
                    <v-flex xs8>
                        <v-select label="Выберите тип"
                                  :items="types"
                                  
                                  item-text="name"
                                  item-value="value"
                                  v-model="newMap.type"></v-select>
                    </v-flex>
                </v-layout>
            <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Количество</v-flex>
                <v-flex xs8>
                      <v-text-field :rules="rules.required"
                              label="Количество" 
                              v-model="newMap.capacity" 
                              type="number"></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Активная</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="newMap.active"
                    label="Активная"
                  ></v-checkbox>
                </v-flex>
            </v-layout>

                
                <v-layout wrap>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="addMapping" class="white--text" :disabled="!valid">Сохранить</v-btn>
                    <v-btn color="red darken-1" @click.native="addMappingDialog = false" class="white--text">Отмена</v-btn>
                </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
  <v-dialog v-model="editMappingDialog" max-width="600">
    <v-card>
        <v-toolbar flat color="#66bb6a">
          <v-toolbar-title left color="blue" class="text--white">Обновить мэппинг</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editMappingDialog = false">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Ячейка</v-flex>
                <v-flex xs8>
                      <v-text-field :rules="rules.required"
                              label="Ячейка" 
                              v-model="editMap.name" 
                              maxlength="5"
                              counter
                              ></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Тип</v-flex>
                    <v-flex xs8>
                        <v-select label="Выберите тип"
                                  :items="types"
                                  
                                  item-text="name"
                                  item-value="value"
                                  v-model="editMap.type"></v-select>
                    </v-flex>
                </v-layout>
            <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Количество</v-flex>
                <v-flex xs8>
                      <v-text-field :rules="rules.required"
                              label="Количество" 
                              v-model="editMap.capacity" 
                              type="number"></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Активная</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="editMap.active"
                    label="Активная"
                  ></v-checkbox>
                </v-flex>
            </v-layout>
            
            
            

            
            <v-layout wrap>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" @click="editMapping" class="white--text" :disabled="!valid">Сохранить</v-btn>
                <v-btn color="red darken-1" @click.native="editMappingDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="deleteMappingDialog" max-width="600">
    
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Удаление мэппинга</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="deleteMappingDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            Вы действительно хотите удалить мэппинг для {{ editMap.name }}?
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="deleteMapping" class="white--text" >Удалить</v-btn>
            <v-btn color="red darken-1" @click.native="deleteMappingDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    addMappingDialog: false,
    editMappingDialog: false,
    deleteMappingDialog: false,
    machine: {},
    headers: [
      {
        sortable: false,
        text: ''
      },
      {
        sortable: true,
        text: 'Ячейка',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Тип',
        value: 'type'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'capacity'
      },
      {
        sortable: true,
        text: '',
        value: 'active'
      }
    ],
    newMap: {
      vendingMachineId: null,
      type: -1,
      name: null,
      capacity: 0,
      price: 0,
      active: false
    },
    editMap: {
      id: null,
      vendingMachineId: null,
      name: null,
      type: -1,
      capacity: 0,
      price: 0,
      active: false
    },
    items: [],
    shopItems: [],
    rules: {
        required: [v => !!v || 'Поле обязательно',],
    },
    valid: false,
    error: null,
    id: null,
    types: [ 
      { name: 'Кофейная', value: 0 }, 
      { name: 'Снековая', value: 1 }, 
      // { name: 'Снековая B', value: 2 }, 
    ],
  }),
  created (){
    this.id = this.$route.params.id;
    this.newMap.vendingMachineId = this.id
  },
  mounted () {
    this.getVendingMachine()
    this.getVendingMachineCells()
  },
  methods: {
    getVendingMachine (){
      this.$http.get('/vendingmachines/' + this.id)
        .then(response => {
          this.machine = response.data
        })
    },
    getVendingMachineCells(){
      this.$http.get('/vendingmachines/' + this.id + '/cells' )
        .then(response => {
          this.items = response.data
          this.items = this.items.sort(this.sortFunction)
        })
    },
    sortFunction(a,b){
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    },
    addMapping(){
      this.error = null;
      this.$http.post('/vendingmachines/' + this.id + '/cells', this.newMap).
          then(response => {
            this.addMappingDialog = false;
            this.newMap = {};
            this.newMap.vendingMachineId = this.id
            this.getVendingMachineCells();
          }).
          catch(e => {
            this.error = e.response.data;
            console.log(e)
          });
      
    },
    pickToEditMapping(item){
      this.editMap = item;
      this.editMappingDialog = true;
    },
    pickToDeleteMapping(item){
      this.editMap = item;
      this.deleteMappingDialog = true;
    },
    editMapping(){
      this.$http.put('/vendingmachines/' + this.id + '/cells/' + this.editMap.id, this.editMap).
          then(response => {
            this.editMappingDialog = false;
            this.editMap = {};
            this.getVendingMachineCells();
          }).
          catch(e => {
            this.error = e.response.data;
              console.log(e)
          });
    },
    deleteMapping(){
      this.$http.delete('/vendingmachines/' + this.id + '/cells/' + this.editMap.id).
        then(response => {
              this.deleteMappingDialog = false
              this.editMap = {};
              this.getVendingMachineCells();
            }).
            catch(e => {
                console.log(e)
            });
    },
    syncMapping(){
      this.$http.put('/vendingmachines/' + this.id + '/items').
          then(response => {

          }).
          catch(e => {
              console.log(e)
          });
    }
  }
}
</script>
